<template>
  <div class="tourism">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="culture_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">
          {{ crumbdata[1].name }}
        </div>
      </div>
      <FourcolLists
        ref="fourcol"
        :urllist="list"
        detailurl="/tourism/detail"
        :param="queryparam"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import BannerImage from "@/components/main/BannerImage";
import FourcolLists from "@/components/main/FourcolLists";

export default {
  props: ["type"],
  components: {
    Header,
    Footer,
    Breadcrumb,
    BannerImage,
    FourcolLists,
    Headers
  },
  created() {
    this.typeChange();
  },
  watch: {
    $route: {
      handler(value) {
        this.queryparam.theType = this.type;
        this.typeChange();
        this.$refs.fourcol.loadData();
      },
      deep: true,
    },
  },
  data() {
    return {
      list: "/culture/cxCulture/list",
      crumbdata: [
        { name: "旅游文化", url: "" },
        { name: "书籍光碟", url: "/books" },
      ],
      queryparam: {
        theType: this.type,
      },
    };
  },
  methods: {
    typeChange() {
      if (this.type == 1) {
        this.crumbdata[1].name = "崇信文物";
      } else if (this.type == 2) {
        this.crumbdata[1].name = "崇信名人";
      } else if (this.type == 3) {
        this.crumbdata[1].name = "崇信民俗";
      } else if (this.type == 4) {
        this.crumbdata[1].name = "崇信名吃";
      } else if (this.type == 5) {
        this.crumbdata[1].name = "崇信特产";
      } else if (this.type == 6) {
        this.crumbdata[1].name = "旅游文学";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
